
import { Component, Vue } from 'vue-property-decorator'
import { Route } from 'vue-router'
import { NewsInterface } from '@/interfaces/news'
import New = NewsInterface.New
import TheNew from '@/components/new/TheNew.vue'
import api from '@/api'

@Component({
  beforeRouteEnter (to: Route, from: Route, next: Function) {
    const newId: number = +to.params.id
    if (!newId) return next({ name: 'News', params: { lang: to.params.lang } })
    api.news.item(newId).then((post: New | null) => {
      if (!post) return next({ name: 'News', params: { lang: to.params.lang } })
      next((vm: NewPage) => {
        document.title = post.name
        vm.post = post
      })
    })
  },
  components: {
    TheNew
  }
})

export default class NewPage extends Vue {
  post: New | null = null
}
