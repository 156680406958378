
import '@/assets/styles/imports/new.sass'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { NewsInterface } from '@/interfaces/news'

@Component
export default class TheNew extends Vue {
  @Prop({ type: Object }) post!: NewsInterface.New

  get time (): string {
    const date: Date = new Date(this.post.published)
    return date.toLocaleDateString(
        this.locale,
        {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
          hour: 'numeric',
          minute: '2-digit'
        })
  }

  get locale () {
    return this.$i18n.locale === 'ru' ? 'ru-RU' : 'en-US'
  }
}
